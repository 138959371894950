/**
 * ******************************************************
 * Copyright (C) 2020-2024 VMware, Inc. All rights reserved.
 * *******************************************************
 *
 * @format
 */

(window as any).process = { env: { DEBUG: undefined } };
import { Buffer } from "buffer";
global.Buffer = global.Buffer || Buffer;
/** IE9, IE10 and IE11 requires all of the following polyfills. **/
import "core-js";
/** ngb-toast requires localize component to complete i18n */
import "@angular/localize/init";
/** IE10 and IE11 requires the following to support `@angular/animation`. */
import "zone.js/dist/zone";
